var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ifArea(1)
        ? _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "위험성평가" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.ifInput("boilingPoint")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "끓는점",
                            name: "boilingPoint",
                          },
                          model: {
                            value: _vm.chem.boilingPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "boilingPoint", $$v)
                            },
                            expression: "chem.boilingPoint",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("hrCodeGrade")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "HR_CODE_GRADE",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            label: "위험문구/유해위험문구 등급",
                            name: "hrCodeGrade",
                          },
                          model: {
                            value: _vm.chem.hrCodeGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "hrCodeGrade", $$v)
                            },
                            expression: "chem.hrCodeGrade",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("hcode")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "유해･위험문구",
                            name: "hcode",
                          },
                          model: {
                            value: _vm.chem.hcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "hcode", $$v)
                            },
                            expression: "chem.hcode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("rcode")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "위험문구",
                            name: "rcode",
                          },
                          model: {
                            value: _vm.chem.rcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "rcode", $$v)
                            },
                            expression: "chem.rcode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("cmrFlag")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "CMR 물질 여부",
                            name: "cmrFlag",
                          },
                          model: {
                            value: _vm.chem.cmrFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "cmrFlag", $$v)
                            },
                            expression: "chem.cmrFlag",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("carcinogens")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "발암성물질",
                            name: "carcinogens",
                          },
                          model: {
                            value: _vm.chem.carcinogens,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "carcinogens", $$v)
                            },
                            expression: "chem.carcinogens",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("mutagens")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "변이원성물질",
                            name: "mutagens",
                          },
                          model: {
                            value: _vm.chem.mutagens,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "mutagens", $$v)
                            },
                            expression: "chem.mutagens",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("reproductiveToxins")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "생식독성물질",
                            name: "reproductiveToxins",
                          },
                          model: {
                            value: _vm.chem.reproductiveToxins,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "reproductiveToxins", $$v)
                            },
                            expression: "chem.reproductiveToxins",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("maleficenceForm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "MALEFICENCE_FORM",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            label: "유해성 노출기준 발생형태",
                            name: "maleficenceForm",
                          },
                          model: {
                            value: _vm.chem.maleficenceForm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "maleficenceForm", $$v)
                            },
                            expression: "chem.maleficenceForm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("maleficenceValue")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "유해성 측정값",
                            name: "maleficenceValue",
                          },
                          model: {
                            value: _vm.chem.maleficenceValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "maleficenceValue", $$v)
                            },
                            expression: "chem.maleficenceValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("maleficenceGrade")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "MALEFICENCE_GRADE",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            label: "비산성 등급",
                            name: "maleficenceGrade",
                          },
                          model: {
                            value: _vm.chem.maleficenceGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "maleficenceGrade", $$v)
                            },
                            expression: "chem.maleficenceGrade",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("maleficenceType")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "MALEFICENCE_TYPE",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            label: "비산/휘발성 구분",
                            name: "maleficenceType",
                          },
                          model: {
                            value: _vm.chem.maleficenceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "maleficenceType", $$v)
                            },
                            expression: "chem.maleficenceType",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.ifArea(2)
        ? _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "유해화학물질" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.ifInput("restrictions")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "제한사항",
                            name: "restrictions",
                          },
                          model: {
                            value: _vm.chem.restrictions,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "restrictions", $$v)
                            },
                            expression: "chem.restrictions",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prohibited")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "금지사항",
                            name: "prohibited",
                          },
                          model: {
                            value: _vm.chem.prohibited,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prohibited", $$v)
                            },
                            expression: "chem.prohibited",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("accidentManuQuantity")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "사고대비물질_제조사용 지정수량(Kg)",
                            name: "accidentManuQuantity",
                          },
                          model: {
                            value: _vm.chem.accidentManuQuantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "accidentManuQuantity", $$v)
                            },
                            expression: "chem.accidentManuQuantity",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("accidentStoreQuantity")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "사고대비물질_보관저장 지정수량(kg)",
                            name: "accidentStoreQuantity",
                          },
                          model: {
                            value: _vm.chem.accidentStoreQuantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "accidentStoreQuantity", $$v)
                            },
                            expression: "chem.accidentStoreQuantity",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.ifArea(3)
        ? _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "제외조건" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.ifInput("exclusionConditions")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "제외조건내용",
                            name: "exclusionConditions",
                          },
                          model: {
                            value: _vm.chem.exclusionConditions,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "exclusionConditions", $$v)
                            },
                            expression: "chem.exclusionConditions",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("exclusionConditionSign")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "CHM_STND_SIGN",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            label: "제외조건부등호",
                            name: "exclusionConditionSign",
                          },
                          model: {
                            value: _vm.chem.exclusionConditionSign,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "exclusionConditionSign", $$v)
                            },
                            expression: "chem.exclusionConditionSign",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("exclusionConditionQuantity")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "정량값",
                            name: "exclusionConditionQuantity",
                          },
                          model: {
                            value: _vm.chem.exclusionConditionQuantity,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.chem,
                                "exclusionConditionQuantity",
                                $$v
                              )
                            },
                            expression: "chem.exclusionConditionQuantity",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("exclusionConditionUnit")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "단위",
                            name: "exclusionConditionUnit",
                          },
                          model: {
                            value: _vm.chem.exclusionConditionUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "exclusionConditionUnit", $$v)
                            },
                            expression: "chem.exclusionConditionUnit",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.ifArea(4)
        ? _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { title: "PRTR" } },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.ifInput("prtrGroupId")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "PRTR_Group_ID",
                            name: "prtrGroupId",
                          },
                          model: {
                            value: _vm.chem.prtrGroupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrGroupId", $$v)
                            },
                            expression: "chem.prtrGroupId",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrMaterialName")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "PRTR_물질명",
                            name: "prtrMaterialName",
                          },
                          model: {
                            value: _vm.chem.prtrMaterialName,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrMaterialName", $$v)
                            },
                            expression: "chem.prtrMaterialName",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("molecularFormula")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "PRTR_조사대상무게함유기준(wt%)",
                            name: "molecularFormula",
                          },
                          model: {
                            value: _vm.chem.molecularFormula,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "molecularFormula", $$v)
                            },
                            expression: "chem.molecularFormula",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrSwcsWtSign")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "CHM_STND_SIGN",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            label: "조사대상무게함유기준_부등호",
                            name: "prtrSwcsWtSign",
                          },
                          model: {
                            value: _vm.chem.prtrSwcsWtSign,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrSwcsWtSign", $$v)
                            },
                            expression: "chem.prtrSwcsWtSign",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrSwcsWtQuantity")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "조사대상무게함유기준_정량값",
                            name: "prtrSwcsWtQuantity",
                          },
                          model: {
                            value: _vm.chem.prtrSwcsWtQuantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrSwcsWtQuantity", $$v)
                            },
                            expression: "chem.prtrSwcsWtQuantity",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrSwcsWtUnit")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "조사대상무게함유기준_단위",
                            name: "prtrSwcsWtUnit",
                          },
                          model: {
                            value: _vm.chem.prtrSwcsWtUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrSwcsWtUnit", $$v)
                            },
                            expression: "chem.prtrSwcsWtUnit",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrSwcsTon")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "조사대상취급량기준(톤/년)",
                            name: "prtrSwcsTon",
                          },
                          model: {
                            value: _vm.chem.prtrSwcsTon,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrSwcsTon", $$v)
                            },
                            expression: "chem.prtrSwcsTon",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrSwcsTonSign")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "CHM_STND_SIGN",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            label: "조사대상취급량기준_부등호",
                            name: "prtrSwcsTonSign",
                          },
                          model: {
                            value: _vm.chem.prtrSwcsTonSign,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrSwcsTonSign", $$v)
                            },
                            expression: "chem.prtrSwcsTonSign",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrSwcsTonQuantity")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "조사대상취급량기준_정량값",
                            name: "prtrSwcsTonQuantity",
                          },
                          model: {
                            value: _vm.chem.prtrSwcsTonQuantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrSwcsTonQuantity", $$v)
                            },
                            expression: "chem.prtrSwcsTonQuantity",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("prtrSwcsTonUnit")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "조사대상취급량기준_단위",
                            name: "prtrSwcsTonUnit",
                          },
                          model: {
                            value: _vm.chem.prtrSwcsTonUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "prtrSwcsTonUnit", $$v)
                            },
                            expression: "chem.prtrSwcsTonUnit",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.ifArea(5)
        ? _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { title: "위험물" } },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.ifInput("dangerousContents")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "위험물내용",
                            name: "dangerousContents",
                          },
                          model: {
                            value: _vm.chem.dangerousContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "dangerousContents", $$v)
                            },
                            expression: "chem.dangerousContents",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("dangerousClass")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "위험물유별구분",
                            name: "dangerousClass",
                          },
                          model: {
                            value: _vm.chem.dangerousClass,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "dangerousClass", $$v)
                            },
                            expression: "chem.dangerousClass",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("dangerousProp")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "위험물성질",
                            name: "dangerousProp",
                          },
                          model: {
                            value: _vm.chem.dangerousProp,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "dangerousProp", $$v)
                            },
                            expression: "chem.dangerousProp",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("dangerousName")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "위험물품명",
                            name: "dangerousName",
                          },
                          model: {
                            value: _vm.chem.dangerousName,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "dangerousName", $$v)
                            },
                            expression: "chem.dangerousName",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("designatedQuantityDose")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "지정수량_정량값",
                            name: "designatedQuantityDose",
                          },
                          model: {
                            value: _vm.chem.designatedQuantityDose,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "designatedQuantityDose", $$v)
                            },
                            expression: "chem.designatedQuantityDose",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("designatedQuantityUnit")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "지정수량_단위",
                            name: "designatedQuantityUnit",
                          },
                          model: {
                            value: _vm.chem.designatedQuantityUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "designatedQuantityUnit", $$v)
                            },
                            expression: "chem.designatedQuantityUnit",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.ifArea(6)
        ? _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "노출기준" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.ifInput("psmAmount")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "공정안전관리 제조∙취급∙저장량(kg/일)",
                            name: "psmAmount",
                          },
                          model: {
                            value: _vm.chem.psmAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "psmAmount", $$v)
                            },
                            expression: "chem.psmAmount",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("bei")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "생물학적노출기준(단위포함 Full text)",
                            name: "bei",
                          },
                          model: {
                            value: _vm.chem.bei,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "bei", $$v)
                            },
                            expression: "chem.bei",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("sensitization")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "과민성 여부 입력(고용노동부, ACGIH 등)",
                            name: "sensitization",
                          },
                          model: {
                            value: _vm.chem.sensitization,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "sensitization", $$v)
                            },
                            expression: "chem.sensitization",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("simpleAsphyxiants")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "질식제 여부 입력(ACGIH, EU 노출기준 등)",
                            name: "simpleAsphyxiants",
                          },
                          model: {
                            value: _vm.chem.simpleAsphyxiants,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "simpleAsphyxiants", $$v)
                            },
                            expression: "chem.simpleAsphyxiants",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("skinNotations")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "피부영향 여부 입력(고용노동부, ACGIH 등)",
                            name: "skinNotations",
                          },
                          model: {
                            value: _vm.chem.skinNotations,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "skinNotations", $$v)
                            },
                            expression: "chem.skinNotations",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("basisCriticalEffects")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "표적장기 입력(예, 간, 신장 등)",
                            name: "basisCriticalEffects",
                          },
                          model: {
                            value: _vm.chem.basisCriticalEffects,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "basisCriticalEffects", $$v)
                            },
                            expression: "chem.basisCriticalEffects",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("twaPpm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "TWA 노출기준입력 ppm",
                            name: "twaPpm",
                          },
                          model: {
                            value: _vm.chem.twaPpm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "twaPpm", $$v)
                            },
                            expression: "chem.twaPpm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("twaMm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "TWA 노출기준입력 mg/m3",
                            name: "twaMm",
                          },
                          model: {
                            value: _vm.chem.twaMm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "twaMm", $$v)
                            },
                            expression: "chem.twaMm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("stelPpm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "STEL 노출기준입력 ppm",
                            name: "stelPpm",
                          },
                          model: {
                            value: _vm.chem.stelPpm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "stelPpm", $$v)
                            },
                            expression: "chem.stelPpm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("stelMm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "STEL 노출기준입력 mg/m3",
                            name: "stelMm",
                          },
                          model: {
                            value: _vm.chem.stelMm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "stelMm", $$v)
                            },
                            expression: "chem.stelMm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("ceilingsPpm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "Celling 노출기준입력 ppm",
                            name: "ceilingsPpm",
                          },
                          model: {
                            value: _vm.chem.ceilingsPpm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "ceilingsPpm", $$v)
                            },
                            expression: "chem.ceilingsPpm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("ceilingsMm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "Celling 노출기준입력 mg/m3",
                            name: "ceilingsMm",
                          },
                          model: {
                            value: _vm.chem.ceilingsMm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "ceilingsMm", $$v)
                            },
                            expression: "chem.ceilingsMm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("lelPpm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "LEL노출기준입력 ppm",
                            name: "lelPpm",
                          },
                          model: {
                            value: _vm.chem.lelPpm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "lelPpm", $$v)
                            },
                            expression: "chem.lelPpm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("lelMm")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "LEL노출기준입력 mg/m3",
                            name: "lelMm",
                          },
                          model: {
                            value: _vm.chem.lelMm,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "lelMm", $$v)
                            },
                            expression: "chem.lelMm",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.ifArea(7)
        ? _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { title: "그 외" } },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.ifInput("remark1")
                  ? _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "고시내용 외 참조내용 입력(예, CMR, PBT 등)",
                            name: "remark1",
                          },
                          model: {
                            value: _vm.chem.remark1,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "remark1", $$v)
                            },
                            expression: "chem.remark1",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("remark2")
                  ? _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "적용기준, 고시내용 외 추가 참조사항 입력",
                            name: "remark2",
                          },
                          model: {
                            value: _vm.chem.remark2,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "remark2", $$v)
                            },
                            expression: "chem.remark2",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ifInput("remark3")
                  ? _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "기타 참조사항",
                            name: "remark3",
                          },
                          model: {
                            value: _vm.chem.remark3,
                            callback: function ($$v) {
                              _vm.$set(_vm.chem, "remark3", $$v)
                            },
                            expression: "chem.remark3",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }